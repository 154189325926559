import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="gallery flexslider">
          <ul className="slides">
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_04.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>STRESS EN DIARREE</h2>
                    <p>
                      Stresssituaties kennen we allemaal, zowel op het werk als
                      privé. Wat velen echter niet weten, is dat stress en
                      nervositeit diarree kunnen veroorzaken. Hier kom je meer
                      te weten over de oorzaken van{" "}
                      <strong>stress en diarree</strong> alsook over wat je
                      ertegen kunt doen.
                    </p>
                    <a
                      href="/feiten-over-diarree/stress-en-diarree/"
                      className="button radius"
                    >
                      Stress en diarree
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_03.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>TIPS BIJ DIARREE</h2>
                    <p>
                      Rond het behandelen van de{" "}
                      <strong>symptomen van diarree</strong> bestaan er nog vele
                      misverstanden en mythes. Hier vind je enkele tips over het
                      behandelen van de symptomen van diarree.
                    </p>
                    <a
                      href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/"
                      className="button radius"
                    >
                      Tips bij diarree
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_02.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>HOE WERKT IMODIUM®</h2>
                    <p>
                      Volgend filmpje toont je de werking van{" "}
                      <strong>IMODIUM®</strong>: hoe <strong>diarree</strong>{" "}
                      belangrijke vloeistoffen en mineralen aan je lichaam
                      onttrekt en hoe <strong>IMODIUM®</strong> het natuurlijke
                      evenwicht in je darm herstelt.
                    </p>
                    <a
                      href="/hoe-werkt-imodium-instant/"
                      className="button radius"
                    >
                      Hoe werkt IMODIUM®
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_01.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>DIARREE OP REIS</h2>
                    <p>
                      De vakantietijd is voor de meeste mensen de mooiste tijd
                      van het jaar. Niemand wil deze kostbare dagen dan ook met{" "}
                      <strong>diarree</strong> op de hotelkamer doorbrengen.
                      Daarom vind je hier enkele nuttig tips en wat meer
                      informatie over het voorkomen en behandelen van de
                      symptomen van&nbsp;<strong>diarree op reis</strong>.
                    </p>
                    <a href="/diarree-op-reis/" className="button radius">
                      Diarree op reis
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row gallery-nav">
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>STRESS EN DIARREE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>TIPS BIJ DIARREE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>HOE WERKT IMODIUM®
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>DIARREE OP REIS
            </a>
          </div>
          <div className="three columns indicator" />
        </div>
        <div className="main row">
          <div className="twelve columns">
            <div className="spotlight row">
              <div className="twelve columns centered">
                <div className="row">
                  <div className="four columns">
                    <img
                      src="/assets/img/veelgeselde_vragen.png"
                      alt="veelgestelde vragen"
                    />
                  </div>
                  <div className="four columns">
                    <h2>
                      <a href="/veelgestelde-vragen-diarree/">
                        VEELGESTELDE VRAGEN
                      </a>
                    </h2>
                  </div>
                  <div
                    className="four columns"
                    style={{
                      "padding-top": "20px"
                    }}
                  >
                    <p>
                      Hier vind je veelgestelde vragen over diarree en IMODIUM®.
                    </p>
                    <a href="/veelgestelde-vragen-diarree/">
                      Verder lezen &gt;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
